<template>
  <v-app>
    <Nav></Nav>

    <v-main class="bgColor">
      <router-view/>
    </v-main>

    <Footer class="footer"></Footer>
  </v-app>
</template>

<script>
import Nav from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Nav
  },
  data: () => ({
  }),
  methods: {
    bookNowClick () {
      window.open('https://www.vagaro.com/houseoffringe/book-now', '_blank')
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "CenturyGothic";
    src: local("CenturyGothic"),
    url(./assets/fonts/CenturyGothic.ttf) format("truetype");
  }

  #app {
    font-family: "CenturyGothic";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  .bgColor {
    /*background-color: #0A101A;*/
    background-color: #000000;
    /*background-color: #FFFFFF;*/
    /*margin-bottom: 20px;*/
    /*background-color: #0C1F34;*/
  }
  .logo {
    min-width: 60%;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .footer {
    /*padding-top: 20px;*/
  }
</style>