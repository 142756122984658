<template>
  <div>
    <v-app-bar app color="#FFFFFF">
      <v-app-bar-nav-icon @click.stop="menuClickHandler()"></v-app-bar-nav-icon>
      <v-toolbar-title>{{getTitle()}}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer color="#000000" dark v-model="isOpen" app absolute top temporary style="position:fixed; top:0; left:0; overflow-y:scroll;">
      <v-list nav>
        <v-list-item v-for="item in items" :key="item.title" link @click="menuOptionClickHandler(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    name: 'Nav',
    props: {
      menuOpen: Boolean
    },
    data: () => ({
      isOpen: false,
      group: null,
      items: [
        { title: 'Home', icon: '' },
        { title: 'About', icon: '' },
        { title: 'Services', icon: '' },
        { title: 'Extensions', icon: '' },
        { title: 'Shop', icon: '' }
      ],
    }),
    created () {
      this.isOpen = this.menuOpen
    },
    methods: {
      menuClickHandler () {
        this.isOpen = !this.isOpen
      },
      menuOptionClickHandler (menuItm) {
        var path = menuItm.title === 'Home' ? '' : menuItm.title.toLowerCase();

        switch (menuItm.title) {
          case 'Home':
            path = '';
            break;
          case 'Services':
            window.open('https://www.vagaro.com/houseoffringe/services', '_blank');
            return;
          case 'Shop':
            window.open('https://shop.saloninteractive.com/store/HouseofFringe/product_categories?group=1', '_blank');
            return;
          default:
            path = menuItm.title.toLowerCase();
            break;
        }

        if (this.$router.history.current && this.$router.history.current.name !== menuItm.title) {
          this.$router.push('/' + path)
        } else {
          this.isOpen = false
        }
      },
      getTitle () {
        if (this.$router.app && this.$router.app._route && this.$router.app._route.name && this.$router.app._route.name !== 'Home') {
          return this.$router.app._route.name
        } else {
          return 'House of Fringe'
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*app bar color="#0C1F34"*/
</style>