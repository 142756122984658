<template>
  <div>
    <v-footer v-bind="localAttrs" :padless="true" color="#FFFFFF" class="d-md-none d--flexjustify-space-around">
      <v-card width="100%" height="180px" class="text-center cardContainer" color="#FFFFFF" outlined elevation="3">
        <v-card-title>Location</v-card-title>
        <v-card-text @click="openMaps()">
          Located in the Haven Salon Suites at<br/><br/>315 Rt 35 Suite #122, Eatontown, NJ 07724
        </v-card-text>
      </v-card>

      <v-card width="100%" height="180px" class="text-center cardContainer" color="#FFFFFF" outlined elevation="3">
        <v-card-title>Connect</v-card-title>
        <v-card-text class="cardText">
          <v-btn v-for="icon in icons" :key="icon" class="mx-4 iconBtn" icon>
            <v-icon size="44px" @click="iconClickHandler(icon)">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <!-- <v-card-text class="d-flex align-center">
          <v-text-field placeholder="Sign up for the newsletter" outlined v-model="newsletterEmail"></v-text-field>
          <v-btn color="black" dark class="sumbmitBtn" @click="submitEmailHandler()">Submit</v-btn>
        </v-card-text> -->
      </v-card>
    </v-footer>

    <v-footer v-bind="localAttrs" :padless="true" color="#FFFFFF" class="d-none d-md-flex justify-space-around">
      <v-card width="49%" height="180px" class="text-center cardContainer" color="#FFFFFF" outlined elevation="3">
        <v-card-title>Location</v-card-title>
        <v-card-text @click="openMaps()">
          Located in the Haven Salon Suites at<br/><br/>315 Rt 35 Suite #122, Eatontown, NJ 07724
        </v-card-text>
      </v-card>

      <v-card width="49%" height="180px" class="text-center cardContainer" color="#FFFFFF" outlined elevation="3">
        <v-card-title>Connect</v-card-title>
        <v-card-text class="cardText">
          <v-btn v-for="icon in icons" :key="icon" class="mx-4 iconBtn" icon>
            <v-icon size="44px" @click="iconClickHandler(icon)">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <!-- <v-card-text class="d-flex align-center">
          <v-text-field placeholder="Sign up for the newsletter" outlined v-model="newsletterEmail"></v-text-field>
          <v-btn color="black" dark class="sumbmitBtn" @click="submitEmailHandler()">Submit</v-btn>
        </v-card-text> -->
      </v-card>
    </v-footer>

    <v-overlay :absolute="false" :value="emailOverlay">
      <v-container fluid>
        <v-card>
          <!-- <div style="float: right;" @click="emailOverlay=false"></div> -->
          <v-card-title>{{emailSubmitTitle}}</v-card-title>
          <v-card-text>{{emailConf}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="emailOverlay=false">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-overlay>

  </div>
</template>

<script>
  import emailjs from 'emailjs-com'

  export default {
    name: 'Footer',
    props: {
    },
    data: () => ({
      newsletterEmail: '',
      emailOverlay: false,
      emailSubmitTitle: 'Confirmation',
      emailConf: 'Thanks for signing up!',
      icons: [
        'mdi-phone',
        // 'mdi-email',
        'mdi-google-maps',
        'mdi-facebook',
        'mdi-instagram'
      ],
      storeHours: [
        {
          day: 'Monday - Tuesday',
          hours: '2:00PM - 8:00PM',
        },
        {
          day: 'Thursday - Friday',
          hours: '9:00AM - 4:00PM',
        },
        {
          day: 'Saturday',
          hours: '10:00AM - 4:00PM',
        }
      ],
      tableHeaders: [{ value: 'day', text: '' },
        { value: 'hours', text: '' }]
    }),
    created () {
    },
    methods: {
      iconClickHandler (inpIcon) {
        switch (inpIcon) {
          case "mdi-phone":
            window.open('tel:9089076062', '_blank')
            break;
          case "mdi-email":
            window.open('mailto:houseoffringe.901@gmail.com', '_blank')
            break;
          case "mdi-google-maps":
            window.open('https://maps.app.goo.gl/YohZxsuEQkCcKtzN6', '_blank')
            break;
          case "mdi-facebook":
            window.open('https://www.facebook.com/JessDelconzo_Beauty-101250081300900/?ti=as', '_blank')
            break;
          case "mdi-instagram":
            window.open('https://www.instagram.com/jess_wefts_and_artistry', '_blank')
            break;
        }
      },
      openMaps () {
        window.open('https://maps.app.goo.gl/YohZxsuEQkCcKtzN6', '_blank')
      },
      submitEmailHandler() {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (this.newsletterEmail.match(mailformat)) {
          try {
            emailjs.send('fringeSignUp', 'signUpTemplate', {email: this.newsletterEmail}, 'user_HXwr8RGUbuwadEBIjrnOr')
            this.newsletterEmail = ''
            this.emailSubmitTitle = 'Confirmation'
            this.emailConf = 'Thanks for signing up!'
            this.emailOverlay = true
          } catch(error) {
            console.log({error})
            this.emailSubmitTitle = 'Error'
            this.emailConf = 'Sorry there was an error. Please try again'
            this.emailOverlay = true
          }
        } else {
          this.emailSubmitTitle = 'Error'
          this.emailConf = 'Please enter a valid email address'
          this.emailOverlay = true
        }
      }
    },
    computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .sumbmitBtn {
    margin-bottom: 35px;
    margin-left: 10px;
  }
  .cardContainer {
    margin: 5px;
    /*margin-right: 5px;*/
  }
  .cardText {
    padding: 0px;
  }
  .iconBtn {
    width: 56px !important;
    height: 56px !important;
  }
  /*footer color="#0A101A"*/
  /*vcard color="#0C1F34"*/
</style>