import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { title: 'House of Fringe' }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
    meta: { title: 'Services' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { title: 'About' }
  },
  {
    path: '/extensions',
    name: 'Extensions',
    component: () => import('../views/Extensions.vue'),
    meta: { title: 'Extensions' }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, current, next) => {
//   if (to.name !== 'EULA' && !window.eulaAccepted) {
//     next({ name: 'EULA' })
//   }

//   next()
//   console.log('in the beforeEach')
// })

export default router
